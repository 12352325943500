.pnf-container {
	@media (max-width: 500px) {
		gap: 10px;
	}

	min-height: 100vh;
	padding: 150px 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 50px;

	.pnf-logo {
		@media (max-width: 768px) {
			width: 20%;
		}

		@media (max-width: 500px) {
			display: none;
		}
	}

	.png-right {
		width: 40%;
		max-width: 350px;

		@media (max-width: 768px) {
			width: 80%;
		}

		h1 {
			color: #555555;
			font-weight: 600;
			font-family: "Roboto", sans-serif;
			font-size: 36px;

			@media (max-width: 500px) {
				font-size: 30px;
			}
		}

		p {
			font-family: 'Hind', sans-serif;
			color: #555555;
			font-weight: 400;
			font-size: 18px;
			margin-bottom: 32px;

			@media (max-width: 500px) {
				font-size: 16px;
				margin-bottom: 10px;
			}
		}
	}

	.btn {
		font-size: 18px;
		padding: 8px 32px;
		border: none;
		border-radius: 4px;
		transition: all 0.4s;
		font-family: "Hind", sans-serif;
		
		&:hover {
			box-shadow: none;
			cursor: pointer;
		}
		
		@media (max-width: 768px) {
			font-size: 16px;
			padding: 4px 16px;
			
		}
	}
	
	.home-btn {
		justify-items: center;
		background: #39c0ba;
		box-shadow: 0px 4px 20px rgba(57, 192, 186, 0.5);
		color: white;
	}
}
