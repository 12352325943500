.card-container {
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 8px;
	border-bottom: 10px solid;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	padding: 34px 32px 24px 32px;
	width: 100%;
	transition: all 0.4s;

	&:hover {
		border-bottom-color: #39c0ba!important;
		scale: 1.02;
	}

	@media (max-width: 768px) {
		padding: 30px 28px 24px 28px;
		border-bottom: 7px solid;
	}
}