.solution {
  padding: 200px 200px 100px 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 64px;

  @media (max-width: 1300px) {
    padding: 200px 48px 100px 48px;
  }

  @media (max-width: 768px) {
    padding: 160px 24px 64px 24px;
  }

  .solution-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
      text-align: left;
    }

    h1 {
      font-family: "Roboto Slab", serif;
      color: #444;
      font-size: 32px;
      margin-bottom: 12px;
      font-weight: 500;

      .ekaant {
        text-transform: uppercase;
        color: #39c0ba;
        font-weight: 600;
      }
    }

    p {
      padding: 8px 100px 32px 100px;
      color: #555;
      font-size: 18px;
      font-weight: 400;

      @media (max-width: 768px) {
        padding: 0;
        padding-bottom: 48px;
      }
    }
  }

  .solution-cards {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    transform: translateX(16px);

    @media (max-width: 1000px) {
      transform: translateX(0);
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .solution-card {
      width: 47%;
      margin: 8px;
      border: 1px solid #39c0ba;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 24px 32px;
      transition: all 0.4s;

      @media (max-width: 768px) {
        width: 100%;
        margin: 0;
        margin-bottom: 16px;
        padding: 24px 24px;
      }

      h2 {
        color: #39c0ba;
        font-family: "Roboto Slab", serif;
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 8px;
      }

      p {
        font-size: 14px;
        color: #555;
        text-align: justify;
      }

      &:hover {
        background-color: #39c0ba;
        box-shadow: 0px 8px 40px rgba(57, 192, 186, 0.6);
        transform: translateY(-8px);
        h2 {
          color: white;
        }
        p {
          color: #b4f8f5;
        }
      }
    }
  }

  .why-ekaant-section {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 80px;
    padding-right: 80px;

    @media (max-width: 1300px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media (max-width: 768px) {
      padding-left: 32px;
      padding-right: 32px;
    }

    h1 {
      color: #444;
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 32px;
    }

    li {
      color: #555;
    }

    .why-ekaant-grid {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .why-ekaant-column {
        padding: 8px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 768px) {
          padding: 0;
          margin-top: 32px;
        }

        img {
          width: 120px;
          height: 120px;
          margin-bottom: 32px;
        }

        ul {
          list-style: none;
          text-align: justify;
        }

        ul li::before {
          content: "\2022";
          color: #39c0ba;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }
}
