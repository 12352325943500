// Highlights

.highlight {
  color: #39C0BA;
  font-weight: bold;
}

// Header ------------------------------

.header {
  padding: 200px 160px 100px;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1300px) {
    height: auto;
    padding: 200px 80px;
    margin-bottom: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 124px 24px 100px;
    height: auto;
  }

  .header-text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 100px;
    width: 50%;
    height: 100%;
    transform: translateY(-40px);
    // margin-top: 72px;

    @media (max-width: 1300px) {
      // padding-right: 100px;
      // width: 50vw;
      // margin-top: 72px;
    }

    @media (max-width: 768px) {
      transform: translateY(0);
      padding: 0;
      margin-top: 48px;
      width: 100%;
    }

    .header-title {
      div {
        font-family: "Roboto Slab", serif;
      }
    }

    h1 {
      font-family: "Roboto Slab", serif;
      color: #444;
      font-size: 36px;
      margin-bottom: 12px;
      font-weight: 600;
      display: flex;
      display: inline;

      @media (max-width: 768px) {
        font-size: 30px;
      }

      .header-color {
        font-family: "Roboto Slab", serif;
        margin-right: 8px;
        color: #39c0ba;
      }

      .text-transition {
        color: #39c0ba;
        margin-left: 4px;
      }

      .text-data {
        .header-color {
          font-family: "Roboto Slab", serif;
          margin-right: 8px;
        }

      }
    }

    p {
      color: #555;
      margin-bottom: 28px;
      font-size: 18px;
      font-weight: 300;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-start;

      .btn {
        font-size: 16px;
        font-weight: 500;
        padding: 12px 40px;
        border: none;
        border-radius: 4px;
        transition: all 0.4s;

        &:hover {
          box-shadow: none;
          cursor: pointer;
        }
      }

      .contact-btn {
        margin-right: 8px;
        background: #39c0ba;
        box-shadow: 0px 4px 20px rgba(57, 192, 186, 0.5);
        color: white;
      }

      .know-more-btn {
        background: #fafafa;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        color: #555;
      }
    }
  }

  .header-img-div {
    display: flex;
    justify-content: center;
    width: 50%;
    height: 100%;
    transform: translateY(-40px);

    @media (max-width: 768px) {
      width: 100%;
      transform: translateY(0);
    }

    img {
      width: 90%;

      @media (max-width: 1300px) {
        width: 80%;
      }

      @media (max-width: 768px) {
        margin-top: 16px;
        width: 100%;
      }
    }
  }

  // Widescreens
  @media (min-width: 1600px) {
    height: 70vh;

    .header-text-div {
      h1 {
        font-size: 40px;
      }

      p {
        font-size: 22px;
      }
    }
  }
}

// About us ---------------------------

.about-us {
  max-width: 100vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 100px 120px;
  background: linear-gradient(180deg,
      #edfffe -28.69%,
      rgba(255, 255, 255, 0) 145.6%);
  height: auto;

  @media (max-width: 1300px) {
    padding: 120px 100px;
    height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 100px 24px 32px 24px;
    height: auto;
  }

  .flex {
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .about-img-div {
    display: flex;
    justify-content: center;
    width: 50%;

    img {
      width: 100%;
      height: 80%;
      transform: translate(0, 48px);

      @media (max-width: 1300px) {
        width: 90%;
        transform: translate(0);
      }

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 40px;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .about-text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-left: 48px;
    // padding-right: 32px;
    text-align: justify;
    width: 50%;

    @media (max-width: 768px) {
      padding: 0;
      width: 100%;
    }

    h1 {
      font-family: "Roboto Slab", serif;
      color: #444;
      font-size: 32px;
      margin-bottom: 12px;
      font-weight: 500;
    }

    p {
      color: #555;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 300;
    }
  }
}

// Privacy Importance ------------------------------

.privacy-imp {
  padding: 100px 120px;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;  
  margin-bottom: auto;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1300px) {
    padding: 50px 48px;
    margin-bottom: auto;
    height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 100px 24px 32px;
    height: auto;
    margin-bottom: auto;
  }

  .privacy-imp-text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 100px;
    width: 50%;
    height: 100%;
    transform: translateY(-40px);

    @media (max-width: 1300px) {
      // padding-right: 100px;
      // width: 50vw;
      // margin-top: 72px;
    }

    @media (max-width: 768px) {
      transform: translateY(0);
      padding: 0;
      margin-top: 48px;
      width: 100%;
    }

    .privacy-imp-title {
      div {
        font-family: "Roboto Slab", serif;
      }
    }

    h1 {
      font-family: "Roboto Slab", serif;
      color: #444;
      font-size: 36px;
      margin-bottom: 12px;
      font-weight: 600;
      display: flex;
      display: inline;

      @media (max-width: 768px) {
        font-size: 30px;
      }

      .privacy-imp-color {
        font-family: "Roboto Slab", serif;
        margin-right: 8px;
        color: #39c0ba;
      }
    }

    p {
      color: #555;
      margin-bottom: 28px;
      font-size: 20px;
      font-weight: 300;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      @media (max-width: 1300px) {
        font-size: 16px;
      }
    }
  }

  .privacy-imp-img-div {
    display: flex;
    justify-content: center;
    width: 50%;
    height: 100%;
    transform: translateY(-40px);

    @media (max-width: 768px) {
      width: 100%;
      transform: translateY(0);
    }

    img {
      width: 90%;

      @media (max-width: 1300px) {
        width: 80%;
      }

      @media (max-width: 768px) {
        margin-top: 16px;
        width: 100%;
      }
    }
  }

  // Widescreens
  @media (min-width: 1600px) {
    height: 70vh;

    .privacy-imp-text-div {
      h1 {
        font-size: 36px;
      }

      p {
        font-size: 22px;
      }
    }
  }
}


// Paradigm --------------------------------------------------
.solutions {
  margin-bottom: auto;
  align-items: center;
  height: 100vh;
  padding: 70px 120px;

  @media (max-width: 1300px) {
    padding: 120px 48px;
    margin-bottom: auto;
    height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 100px 24px 32px;
    height: auto;
    margin-bottom: auto;
  }

  .solutions-heading-title {
    margin-bottom: 32px;
    font-size: 36px;
    position: relative;
    width: fit-content;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    font-family: "Roboto Slab", serif;
    color: #444;
    font-weight: bold;
  }
}


.solutions-card-div {
  width: 85%;
  display: flex;
  justify-content: center;
  gap: 4rem;
  text-align: left;
  // padding: 50px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 2rem;
  }

  .horizontal-line {
    margin-top: 30px;

    @media (max-width: 768px) {
      margin-top: 12px;
    }

    @media (max-width: 1300px) {
      margin-top: 20px;
    }
  }

  .solution-header-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 36px;
    position: relative;
    width: fit-content;
    font-family: "Roboto Slab", serif;
    color: #444;
    font-weight: bold;
  }

  .solutions-card-wrapper {
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    text-align: left;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .solutions-card-img {
      height: 100px;
      width: 100px;
      margin-bottom: 3px;
      object-fit: contain;

      @media (max-width: 760px) {
        height: 70px;
        width: 70px;
      }
    }


    .solutions-link {
      // display: 'flex';
      // justify-content: space-between;
      margin-top: 20px;
    }

    h1 {
      font-size: 32px;
      font-weight: 500;
      font-family: "Roboto Slab", serif;
      margin-bottom: 1rem;
      color: #2D2D2D;
      flex: none;
      text-align: center;
      font-style: normal;

      @media (max-width: 768px) {
        font-size: 30px;
        text-align: center;
      }

    }

    p {
      font-family: "Hind", sans-serif;
      color: #555555;
      font-size: 18px;
      // font-size: small;
      font-weight: 400;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      @media (max-width: 1300px) {
        font-size: 17px;
      }
    }

    .solutions-points-div {
      display: flex;
      flex-direction: row;
      gap: 15px;
      width: 100%;
      font-family: "Hind", sans-serif;
      font-size: 18px;
      color: #555555;
      margin-top: 20px;
      margin-bottom: 40px;
      font-weight: 400;

      @media (max-width: 760px) {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

    }

    .solutions-points-col {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      row-gap: 15px;
      font-size: small;
      margin-top: 15px;

      @media (max-width: 760px) {
        // flex-direction: column;
        width: 100%;
      }

      @media (max-width: 1300px) {
        // width: max-content
        width: 100%;
        font-size: x-small;
      }
    }
  }

  .solutions-card-wrapper:hover {
    transform: scale(1);
  }

  // Widescreens
  @media (min-width: 1600px) {
    height: 70vh;

    .solutions-card-div {
      h1 {
        font-size: 40px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}


// Articles --------------------------------

.articles {
  // margin-bottom: 64px;
  align-items: center;
  padding: 100px 100px;
  height: 100%;

  @media (max-width: 1300px) {
    margin-bottom: auto;
    // padding: 20px 50px;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 100px 24px 32px;
    // height: auto;
    // margin-bottom: auto;
  }

  .articles-heading-title {
    margin-bottom: 32px;
    font-size: 36px;
    position: relative;
    width: fit-content;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    font-family: "Roboto Slab", serif;
    color: #444;
    font-weight: bold;
  }

  .articles-div {
    display: flex;
    flex-direction: row;
    margin-left: 3rem;
    margin-right: 3rem;
    height: 500px;
    flex-basis: 1;
    width: auto;

    @media (max-width: 640px) {
      flex-direction: column;
      // padding: ;
      margin-left: auto;
      margin-right: auto;
      height: 400px;
      width: 95%;
    }

    @media (max-width: 1300px) {

    }

  }
}

// Swiper
div.swiper-button-next {
  color: #000000;
  margin-right: 0px;
  position: absolute;

  @media (max-width: 640px){
    background-color: #39C0BACC;
    opacity: 0.9 !important;
    color: #FFFFFF;
    padding: 25px;
  }
}

div.swiper-button-prev{
  color: #000000;
  margin-left: 0px;
  position: absolute;

  @media (max-width: 640px){
    background-color: #39C0BACC;
    color: #FFFFFF;
    opacity: 0.9 !important;
    padding: 25px;
  }
}

span.swiper-pagination-bullet {
  background-color: black;
}

.swiper-pagination{
  position: relative !important;
  margin-top: 5%;
}

// custom scrollbar
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #39c0ba;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #39c0ba;
}