.use-cases {
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 200px 160px 100px 160px;

  @media (max-width: 1300px) {
    padding: 200px 48px 100px 48px;
  }

  @media (max-width: 768px) {
    padding: 160px 24px 64px 24px;
  }

  h1 {
    font-family: "Roboto Slab", serif;
    color: #444;
    font-size: 32px;
    margin-bottom: 80px;
    font-weight: 500;

    @media (max-width: 768px) {
      margin-bottom: 60px;
    }
  }

  .use-cases-div {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .use-case {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 48px;
      padding: 24px 32px;
      border-bottom: 8px solid #ffffff;
      transition: all 0.4s;

      @media (max-width: 768px) {
        flex-direction: column;
        padding: 32px 24px;
        margin-bottom: 32px;
        border-bottom: 8px solid #39c0ba;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 20px;
      }

      .use-case-img-div {
        img {
          width: 80%;
          margin-right: 40px;

          @media (max-width: 1300px) {
            margin-right: 24px;
          }

          @media (max-width: 768px) {
            width: 60%;
            margin-bottom: 16px;
            margin-right: 0;
          }
        }
      }

      .use-case-text-div {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 35vw;

        padding: 24px 40px;
        border-radius: 8px;

        border-bottom: 8px solid #39c0ba;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 20px;

        @media (max-width: 1300px) {
          width: 50vw;
          padding: 24px 40px;
        }

        @media (max-width: 768px) {
          width: 100%;
          padding: 0;
          background-color: white;

          border-bottom: none;
          border-radius: 0;
          box-shadow: none;
        }

        .use-case-title {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-bottom: 12px;

          img {
            margin-right: 12px;
          }

          h2 {
            color: #444;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 500;
            transform: translateY(2px);
          }
        }

        p {
          color: #555;
          font-size: 16px;
          font-weight: 300;
          text-align: justify;
        }
      }
    }
    .row-reverse {
      flex-direction: row-reverse;
    }
  }
}
