.team {
  padding: 200px 160px 100px 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1300px) {
    padding: 200px 48px 100px 48px;
  }

  @media (max-width: 768px) {
    padding: 160px 24px 64px 24px;
  }

  .team-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
      text-align: center;
      align-items: center;
    }

    h1 {
      font-family: "Roboto Slab", serif;
      color: #444;
      font-size: 36px;
      margin-bottom: 12px;
      font-weight: 500;
    }

    h4 {
      color: #39c0ba;
      text-transform: uppercase;
      font-size: 24px;
      margin: 48px auto 24px auto;
      font-family: "Roboto Slab", serif;

      @media (max-width: 768px) {
        margin: 40px auto 24px auto;
      }
    }

    p {
      color: #555;
      font-size: 18px;
      font-weight: 400;
      padding: 8px 100px 32px 100px;

      @media (max-width: 768px) {
        padding: 0;
        padding-bottom: 48px;
      }
    }
  }

  .team-cards-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 32px;
    }

    .team-card {
      padding: 32px;
      width: 26%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      border-bottom: 8px solid #ffffff;
      transition: all 0.4s;
      border-radius: 4px;
      cursor: pointer;

      img {
        width: 160px;
        height: 160px;
        border-radius: 100%;
        filter: grayscale(100%);
        object-fit: cover;
        object-position: top;
      }

      // .flex {
      //   display: flex;

      //   img {
      //     width: 20px;
      //     height: 20px;
      //     margin-top: 18px;
      //     margin-left: 8px;
      //   }
      // }

      @media (max-width: 1300px) {
        width: 30%;
      }

      @media (max-width: 768px) {
        width: 100%;
        padding: 32px 0px;

        border-bottom: 8px solid #39c0ba;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 20px;
      }

      h2 {
        color: #444;
        font-size: 24px;
        font-weight: 500;
        margin-top: 12px;
        text-align: center;
      }

      h4 {
        color: #39c0ba;
        font-size: 18px;
        font-weight: 600;
        margin-top: -4px;
        margin-bottom: 12px;
      }

      p {
        // text-align: center;
        font-size: 14px;
        color: #555;

        @media (max-width: 768px) {
          padding: 0px 32px;
        }
      }

      .linkedin-logo {
        width: 20px;
        margin-right: auto;
        margin-top: 16px;

        @media (max-width: 768px) {
          margin-left: 32px;
          width: 24px;
        }
      }

      &:hover {
        border-bottom: 8px solid #39c0ba;
        -webkit-box-shadow: 10px 10px 40px 10px rgba(240, 240, 240, 1);
        -moz-box-shadow: 10px 10px 40px 10px rgba(240, 240, 240, 1);
        box-shadow: 10px 10px 40px 10px rgba(240, 240, 240, 1);
      }
    }
  }
}
