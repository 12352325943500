// usecase-Header ------------------------------

.usecase-header {
	padding: 200px 160px 100px 160px;
	width: 100%;
	min-height: 100vh;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 100px;

	@media (max-width: 1300px) {
		padding: 200px 48px 100px 48px;
	}

	@media (max-width: 768px) {
		flex-direction: column-reverse;
		padding: 124px 24px 20px 24px;
		height: auto;
		gap: 20px;
	}

	.usecase-header-text-div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 50%;
		height: 100%;

		@media (max-width: 1300px) {
			// padding-right: 100px;
			// width: 50vw;
			// margin-top: 72px;
		}

		@media (max-width: 768px) {
			padding: 0;
			width: 100%;
			align-items: center;
		}

		h1 {
			font-family: 'Roboto Slab', serif;
			color: #444;
			font-size: 36px;
			margin-bottom: 12px;
			font-weight: 600;
			display: flex;
			display: inline;

			@media (max-width: 768px) {
				font-size: 30px;
				text-align: center;
			}

			.text-data {
				font-family: 'Roboto Slab', serif;
				margin-right: 8px;
			}

			.text-transition {
				color: #39c0ba;
				// margin-left: 4px;
			}
		}

		.usecase-header-title-2 {
			margin-bottom: 32px;
			font-size: 28px;
			position: relative;
			width: fit-content;
			margin-left: auto;
			margin-right: auto;

			hr {
				width: 84%;
				height: 2px;
				border: none;
				background-color: rgb(57, 192, 186);
				margin: auto;
				position: absolute;
				bottom: -7px;
				left: 8%;
				border-radius: 10px;
			}
		}

		p {
			color: #555;
			margin-bottom: 28px;
			font-size: 18px;
			font-weight: 300;

			@media (max-width: 768px) {
				font-size: 16px;
				text-align: justify;
			}
		}
	}

	.usecase-header-img-div {
		display: flex;
		justify-content: center;
		width: 50%;
		height: 100%;

		@media (max-width: 768px) {
			width: 100%;
			margin-bottom: 50px;
		}

		img {
			width: 90%;

			@media (max-width: 1300px) {
				width: 80%;
			}

			@media (max-width: 768px) {
				margin-top: 16px;
				width: 100%;
			}
		}
	}

	// Widescreens
	@media (min-width: 1600px) {
		.usecase-header-text-div {
			h1 {
				font-size: 40px;
			}

			p {
				font-size: 22px;
			}
		}
	}
}

.usecase-card-div {
	width: 80%;
	display: flex;
	justify-content: center;
	gap: 4rem;
	text-align: left;

	@media (max-width: 768px) {
		flex-direction: column;
		width: 100%;
		gap: 2rem;
	}

	.usecase-card-wrapper {
		h1 {
			font-size: 1.3rem;
			font-weight: 500;
			font-family: 'Hind';
			margin-bottom: 1rem;
			color: black;
		}

		.usecase-points {
			display: flex;
			flex-direction: column;
			align-items: start;
			gap: 10px;
			width: 100%;
			font-size: 14px;
			color: #555555;
		}
	}
}

.reachout-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin-bottom: 100px;

	h3 {
		font-family: 'Roboto Slab', serif;
		font-size: 20px;
		color: #444444;
	}

	@media (max-width: 768px) {
		margin-top: 60px;
	}
}

.keyfeature-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	h1 {
		font-family: 'Roboto Slab', serif;
		color: #444;
		font-size: 28px;
		margin-bottom: 12px;
		font-weight: 600;
		position: relative;

		hr {
			width: 84%;
			height: 2px;
			border: none;
			background-color: rgb(57, 192, 186);
			margin: auto;
			position: absolute;
			bottom: -10px;
			left: 8%;
			border-radius: 10px;
		}

		@media (max-width: 768px) {
			font-size: 28px;
		}

		@media (max-width: 500px) {
			font-size: 24px;
		}
	}

	.ltr {
		flex-direction: row;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	.rtl {
		flex-direction: row-reverse;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}
}

.keyfeature-section {
	padding: 80px 160px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4rem;

	@media (max-width: 1300px) {
		padding: 100px 80px;
	}

	@media (max-width: 768px) {
		padding: 50px 24px;
		gap: 2rem;
	}

	.keyfeature-section-text-div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 50%;
		height: 100%;

		@media (max-width: 1300px) {
			// padding-right: 100px;
			// width: 50vw;
			// margin-top: 72px;
		}

		@media (max-width: 768px) {
			padding: 0;
			width: 100%;
		}

		.keyfeature-section-title {
			// text-transition-font
			div {
				font-family: 'Roboto Slab', serif;
			}
		}

		h2 {
			font-family: 'Roboto Slab', serif;
			color: #444444;
			font-size: 24px;
			margin-bottom: 12px;
			font-weight: 600;
			display: flex;
			display: inline;

			@media (max-width: 768px) {
				font-size: 20px;
			}
		}

		.keyfeature-section-description {
			color: #555;
			margin-bottom: 32px;
			font-size: 16px;
			font-weight: 300;
		}

		.keyfeature-points-container {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 14px;

			@media (max-width: 500px) {
				grid-template-columns: repeat(1, 1fr);
			}

			p {
				color: black;
				font-size: 16px;
				font-weight: 300;
			}
		}
	}

	.keyfeature-section-img-div {
		width: 55%;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

		@media (max-width: 768px) {
			width: 100%;
		}

		img {
			width: 100%;
			margin: auto;
		}
	}

	// Widescreens
	@media (min-width: 1600px) {
		.keyfeature-section-text-div {
			h1 {
				font-size: 40px;
			}

			p {
				font-size: 22px;
			}
		}
	}
}

.schedule-demo-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 80px 120px 160px 120px;
	background: linear-gradient(182.47deg, #e1fffe 6.33%, #ffffff 79.78%);

	@media (max-width: 768px) {
		padding: 124px 24px;
	}

	h1 {
		font-family: 'Roboto Slab', serif;
		color: #444;
		font-size: 28px;
		margin-bottom: 28px;
		font-weight: 600;
		position: relative;
		text-align: center;

		@media (max-width: 768px) {
			margin-bottom: 16px;
		}
	}

	.quote {
		color: #555555;
		font-size: 16px;
		margin-bottom: 32px;
		text-align: center;
	}

	h2 {
		font-family: 'Roboto Slab', serif;
		color: #444;
		font-size: 20px;
		margin: 100px 10px 50px 10px;
		font-weight: 500;
		position: relative;
		text-align: center;
		text-transform: uppercase;

		@media (max-width: 768px) {
			text-transform: none;
			margin: 100px 10px 40px 10px;
		}
	}

	.usecase-sectors-container {
		display: flex;
		justify-content: center;
		align-items: stretch;
		gap: 3.5rem;
		flex-wrap: wrap;

		div {
			max-width: 29%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: 34px 32px 24px 32px;

			@media (max-width: 1000px) {
				max-width: 40%;
			}

			@media (max-width: 550px) {
				max-width: 80%;
			}
		}

		img {
			width: 100%;
			object-fit: contain;
			object-position: top;

			@media (max-width: 768px) {
				min-height: 100px;
			}
		}

		h3 {
			font-size: 20px;
			font-family: 'Roboto Slab', serif;
			font-weight: 500;
			text-align: center;
			margin: 20px 0 7px 0;
		}

		p {
			font-size: 14px;
			font-weight: 300;
			text-align: center;
		}
	}
}
