.btn {
	font-size: 16px;
	padding: 12px 40px;
	border: none;
	border-radius: 4px;
	transition: all 0.4s;

	&:hover {
		box-shadow: none;
		cursor: pointer;
	}

	&:disabled {
		opacity: 0.7;
	}
}

.primary-btn {
	background: #39c0ba;
	box-shadow: 0px 4px 20px rgba(57, 192, 186, 0.5);
	color: white;
}
