.demo {
	padding: 200px 160px 100px 160px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 64px;

	@media (max-width: 1300px) {
		padding: 200px 48px 100px 48px;
	}

	@media (max-width: 768px) {
		padding: 160px 24px 64px 24px;
	}

	.demo-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		gap: 100px;
		align-items: stretch;

		@media (max-width: 768px) {
			flex-direction: column-reverse;
		}

		.demo-left {
			display: flex;
			flex-direction: column;
			gap: 25px;
			max-width: 400px;
			width: 45%;

			@media (max-width: 768px) {
				width: 100%;
				max-width: unset;
			}

			h1 {
				color: #444444;
				font-family: 'Roboto Slab';
				font-weight: 500;
				font-size: 36px;

				@media (max-width: 500px) {
					font-size: 32px;
				}
			}

			.demo-description {
				color: #555555;
				font-size: 16px;
			}

			.key-points {
				display: flex;
				flex-direction: column;
				gap: 10px;
				font-size: 18px;
				color: black;
			}
		}

		.form-div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 20px;
			background: #ffffff;
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
			padding: 30px;
			width: 50%;

			@media (max-width: 768px) {
				width: 100%;
			}

			h1 {
				font-family: 'Roboto Slab';
				font-weight: 500;
				text-align: center;
				font-size: 24px;
			}

			.demo-form-container {
				display: flex;
				flex-direction: column;
				gap: 24px;
			}

			.name-container {
				display: flex;
				gap: 14px;
				width: 100%;

				@media (max-width: 500px) {
					flex-direction: column;
				}
			}

			.input-container {
				display: flex;
				flex-direction: column;
				width: 100%;
			}

			.required {
				color: #EB4335;
			}

			input {
				outline: none;
				border: 1px solid #dbdbdb;
				box-sizing: border-box;
				border-radius: 4px;
				padding: 8px 12px;
				background-color: transparent;
				width: 100%;
			}

			input:hover {
				border: 1px solid #b6b6b6;
			}

			input:focus {
				border: 1px solid #969696;
			}

			input[type='checkbox'] {
				accent-color: #39c0ba;
				color: white;
				display: inline-block;
				width: fit-content;
			}

			label,
			p {
				font-size: 16px;
				font-weight: 400;
				color: #333333;
			}

			.agreement-label {
				margin: 10px 0;
				font-size: 14px!important;
			}

			.demo-form-note {
				font-size: 14px;
			}
		}
	}
}
