.contact-us {
  padding: 200px 160px 100px 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 64px;

  @media (max-width: 1300px) {
    padding: 200px 48px 100px 48px;
  }

  @media (max-width: 768px) {
    padding: 160px 24px 64px 24px;
  }

  .contact-us-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
      text-align: left;
      align-items: flex-start;
    }

    h1 {
      font-family: "Roboto Slab", serif;
      color: #444;
      font-size: 32px;
      margin-bottom: 12px;
      font-weight: 500;
    }

    p {
      color: #555;
      font-size: 18px;
      font-weight: 400;

      .email {
        color: #39c0ba;
      }
    }
  }

  .contact-us-div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    img {
      width: 36vw;

      @media (max-width: 768px) {
        width: 90%;
        transform: translateX(32px);
      }
    }

    .contact-form-div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 30vw;
      margin-top: 100px;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 48px;
      }

      input,
      textarea {
        outline: none;
        border: 1px solid #39c0ba!important;
        border: none;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 16px 24px;
        margin: 4px 0;
        background-color: #fafafa;
      }

      .btn {
        margin-top: 8px;
        font-size: 16px;
        padding: 12px 24px;
        border: none;
        border-radius: 4px;
        // margin-right: 8px;
        background: #39c0ba;
        box-shadow: 0px 4px 20px rgba(57, 192, 186, 0.5);
        color: white;
        transition: all 0.4s;

        &:hover {
          box-shadow: none;
          cursor: pointer;
        }
      }
    }
  }
}
