* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Hind", sans-serif;
  scroll-behavior: smooth;
}

.desktop-nav {
  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 1050px) {
    padding: 32px 16px;
  }

  @media (max-width: 1100px) {
    padding: 32px;
  }

  @media (max-width: 1200px) {
    padding: 32px 40px;
  }

  @media (max-width: 1300px) {
    padding: 32px 48px;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.2s;
  z-index: 999;

  padding: 48px 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .desktop-nav-links {
    a {
      margin: auto 32px;
      text-decoration: none;
      color: #444444;
      font-size: 18px;
      font-weight: 600;
      transition: all 0.2s;

      @media (max-width: 1050px) {
        margin: auto 16px;
      }
      @media (max-width: 900px) {
        margin: auto 8px;
      }
      &:hover {
        color: #39c0ba;
      }
    }
  }
}

.mobile-nav {
  @media (min-width: 769px) {
    display: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  padding: 32px 24px;
  transition: all 0.2s;

  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.mobile-nav-menu {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #39c0ba;
  transform: translateX(-100%);

  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 24px;
  }

  .mobile-nav-links {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;

    a, .nav-link {
      margin: 14px 16px;
      text-decoration: none;
      color: white;
      // text-transform: uppercase;
      font-size: 26px;
      font-weight: 600;
      // letter-spacing: 2px;
      padding: 0;
      border-radius: 4px;
      border-bottom: 6px solid #39c0ba;
    }

    .contact-us-link {
      margin-top: 24px;
      background: #fafafa;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      color: #555;
      font-weight: 600;
      font-size: 18px;
      // text-transform: uppercase;
      width: 60%;
      text-align: center;
      padding: 8px;
      border: none;
      border-radius: 4px;
    }
  }

  .accordion-panel-item {
    text-align: center;
    color: white;
    font-size: 22px;
    padding: 5px;
    font-weight: 500;
    cursor: pointer;
  }

  .social {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .social-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin: 64px 24px;
      }
    }
  }
}

.highlight-desktop-nav {
  background-color: white;
  padding-top: 16px;
  padding-bottom: 16px;
  box-shadow: 0px 4px 20px #ececec;
}

.highlight-mobile-nav {
  background-color: white;
  padding: 10px 24px;
  box-shadow: 0px 4px 20px #ececec;
}
