.privacy-container {
	padding: 200px 160px 100px 160px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 64px;

	h1 {
		font-family: 'Roboto Slab';
		font-weight: 500;
		font-size: 40px;
		margin-bottom: 16px;
	}

	h2 {
		font-family: 'Roboto Slab';
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 8px;
	}

	p {
		color: #555555;
		font-size: 14px;
		width: 90%;
		margin-bottom: 10px;
	}

	@media (max-width: 1300px) {
		padding: 200px 48px 100px 48px;
	}

	@media (max-width: 768px) {
		padding: 160px 24px 64px 24px;

		h1 {
			font-size: 32px;
		}

		h2 {
			font-size: 16px;
		}

		p {
			font-size: 12px;
		}
	}
}
