// incog-Header ------------------------------

.incog-header {
	padding: 200px 160px 100px 160px;
	width: 100%;
	min-height: 100vh;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 100px;

	@media (max-width: 1300px) {
		padding: 200px 48px 100px 48px;
	}

	@media (max-width: 768px) {
		flex-direction: column-reverse;
		padding: 124px 24px 20px 24px;
		height: auto;
		gap: 20px;
	}

	.incog-header-text-div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 50%;
		height: 100%;

		@media (max-width: 1300px) {
			// padding-right: 100px;
			// width: 50vw;
			// margin-top: 72px;
		}

		@media (max-width: 768px) {
			transform: translateY(0);
			padding: 0;
			width: 100%;
		}

		h1 {
			font-family: 'Roboto Slab', serif;
			color: #444;
			font-size: 36px;
			margin-bottom: 12px;
			font-weight: 600;
			display: flex;
			display: inline;

			@media (max-width: 768px) {
				font-size: 30px;
			}

			.text-data {
				font-family: 'Roboto Slab', serif;
				margin-right: 8px;
			}

			.text-transition {
				color: #39c0ba;
				// margin-left: 4px;
			}
		}

		p {
			color: #555;
			margin-bottom: 42px;
			font-size: 18px;
			font-weight: 300;

			@media (max-width: 768px) {
				font-size: 16px;
			}
		}
	}

	.incog-header-img-div {
		display: flex;
		justify-content: center;
		width: 80%;
		height: 100%;

		@media (max-width: 768px) {
			width: 100%;
			margin-bottom: 50px;
		}

		img {
			width: 90%;

			@media (max-width: 1300px) {
				width: 80%;
			}

			@media (max-width: 768px) {
				margin-top: 16px;
				width: 100%;
			}
		}
	}

	// Widescreens
	@media (min-width: 1600px) {
		.incog-header-text-div {
			h1 {
				font-size: 40px;
			}

			p {
				font-size: 22px;
			}
		}
	}
}

.incog-card-div {
	width: 80%;
	display: flex;
	justify-content: center;
	gap: 4rem;
	text-align: left;

	@media (max-width: 768px) {
		flex-direction: column;
		width: 90%;
		gap: 2rem;
	}

	.incog-card-wrapper {
		h1 {
			font-size: 1.3rem;
			font-weight: 500;
			font-family: 'Hind';
			margin-bottom: 1rem;
		}

		.incog-points {
			display: flex;
			flex-direction: column;
			align-items: start;
			gap: 10px;
			width: 100%;
			font-size: 14px;
			color: #555555;
		}
	}
}

.keyfeature-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	h1 {
		font-family: 'Roboto Slab', serif;
		color: #444;
		font-size: 36px;
		margin-bottom: 12px;
		font-weight: 600;
		position: relative;

		@media (max-width: 768px) {
			font-size: 30px;
		}
	}

	.ltr {
		flex-direction: row;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	.rtl {
		flex-direction: row-reverse;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}
}

.keyfeature-section {
	padding: 80px 160px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4rem;

	@media (max-width: 1300px) {
		padding: 100px 80px;
	}

	@media (max-width: 768px) {
		padding: 50px 24px;
		gap: 2rem;
	}

	.keyfeature-section-text-div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 50%;
		height: 100%;

		@media (max-width: 1300px) {
			// padding-right: 100px;
			// width: 50vw;
			// margin-top: 72px;
		}

		@media (max-width: 768px) {
			padding: 0;
			width: 100%;
		}

		.keyfeature-section-title {
			// text-transition-font
			div {
				font-family: 'Roboto Slab', serif;
			}
		}

		h2 {
			font-family: 'Roboto Slab', serif;
			color: #444;
			font-size: 24px;
			margin-bottom: 12px;
			font-weight: 600;
			display: flex;
			display: inline;

			@media (max-width: 768px) {
				font-size: 30px;
			}
		}

		.keyfeature-section-description {
			color: #555;
			margin-bottom: 28px;
			font-size: 14px;
			font-weight: 300;

			@media (max-width: 768px) {
				font-size: 16px;
			}
		}

		.keyfeature-points-container {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 10px;

			@media (max-width: 500px) {
				grid-template-columns: repeat(1, 1fr);
			}
		}
	}

	.keyfeature-section-img-div {
		width: 60%;

		@media (max-width: 768px) {
			width: 100%;
		}

		img {
			width: 100%;
			margin: auto;
		}
	}

	// Widescreens
	@media (min-width: 1600px) {
		.keyfeature-section-text-div {
			h1 {
				font-size: 40px;
			}

			p {
				font-size: 22px;
			}
		}
	}
}

.usecase-sector-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 80px 120px 160px 120px;
	background: linear-gradient(182.47deg, #e1fffe 6.33%, #ffffff 79.78%);
	width: 100%;

	@media (max-width: 1300px) {
		padding: 100px 80px;
	}

	@media (max-width: 768px) {
		padding: 124px 24px;
	}

	.primary-header-title {
		font-family: 'Roboto Slab', serif;
		color: #444;
		font-size: 28px;
		margin-bottom: 66px;
		font-weight: 600;
		position: relative;
		text-align: center;
		position: relative;

		hr {
			width: 84%;
			height: 2px;
			border: none;
			background-color: rgb(57, 192, 186);
			margin: auto;
			position: absolute;
			bottom: -8px;
			left: 8%;
			border-radius: 10px;
		}

		@media (max-width: 768px) {
			font-size: 24px;
			margin-bottom: 28px;
		}
	}

	.quote {
		color: #555555;
		font-size: 14px;
		margin-bottom: 32px;
		text-align: center;
	}

	h2 {
		font-family: 'Roboto Slab', serif;
		color: #444;
		font-size: 20px;
		margin: 100px 10px 32px 10px;
		font-weight: 500;
		position: relative;
		text-align: center;
		text-transform: uppercase;

		@media (max-width: 768px) {
			font-size: 14px;
		}
	}
}

.sectors-container {
	display: flex;
	justify-content: center;
	align-items: stretch;
	gap: 3.5rem;
	flex-wrap: wrap;

	div {
		max-width: 29%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 34px 32px 24px 32px;

		@media (max-width: 650px) {
			max-width: 80%;
		}
	}

	img {
		width: 100%;
		object-fit: contain;
		object-position: top;

		@media (max-width: 768px) {
			min-height: 100px;
		}
	}

	h3 {
		font-size: 20px;
		font-family: 'Roboto Slab', serif;
		font-weight: 500;
		text-align: center;
		margin: 20px 0 7px 0;
	}

	p {
		font-size: 14px;
		font-weight: 300;
		text-align: center;
	}
}
