.footer {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 0 150px;

	@media (max-width: 768px) {
		padding: 0 24px;
	}

	h1 {
		color: #444444;
		font-weight: 500;
		font-size: 28px;
		margin-bottom: 52px;
		font-family: 'Roboto Slab';
	}

	.client-images {
		margin: 0 auto 64px auto;
		width: 100%;
		max-width: 1400px;
		display: flex;
		align-self: stretch;
		justify-content: center;
		flex-wrap: wrap;

		.client-image:nth-child(1) {
			width: 48%;

			@media (max-width: 500px) {
				width: 98%;
			}
		}

		.client-image:nth-child(2) {
			width: 48%;

			@media (max-width: 500px) {
				width: 98%;
			}
		}

		.client-image:nth-child(3) {
			width: 13.3%;

			@media (max-width: 500px) {
				width: 27%;
			}
		}

		.client-image:nth-child(4) {
			width: 18.5%;

			@media (max-width: 500px) {
				width: 28%;
			}
		}

		.client-image:nth-child(5) {
			width: 23.5%;

			@media (max-width: 500px) {
				width: 38%;
			}
		}

		.client-image:nth-child(6) {
			width: 17.3%;

			@media (max-width: 500px) {
				width: 48%;
				height: 100px;
			}
		}

		.client-image:nth-child(7) {
			width: 17.3%;

			@media (max-width: 500px) {
				width: 48%;
				height: 100px;
			}
		}

		.client-image {
			box-sizing: border-box;
			padding: 20px;
			margin: 1%;
			background-color: white;
			border-radius: 8px;
			box-shadow: 0px 0px 46.1217px rgba(57, 192, 186, 0.15);
			border-bottom: 8px solid transparent;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all 0.4s;

			&:hover {
				box-shadow: 0px 0px 20px rgba(57, 192, 186, 0.15);
				border-bottom: 8px solid #39c0ba;
			}

			@media (max-width: 500px) {
				padding: 10px;
			}
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	.footer-body {
		width: 100%;
		padding: 24px 0;
		background-color: #f5f5f5;

		@media (max-width: 768px) {
			padding: 32px 0;
		}

		p {
			color: #666;

			@media (max-width: 768px) {
				flex-direction: column;
				font-size: 16px;
			}
		}
	}

	.footer-gradient {
		width: 100%;
		height: 24px;
		background: linear-gradient(90.26deg, #39c0ba -8.08%, #63f1ea 108.59%);
	}
}
