.blogcard-container {
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 8px;
	border-bottom: 10px solid;
	border-bottom-color: #39c0ba !important;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	transition: all 0.4s;
	// margin-bottom: 15px;
	height: 100%;
	width: 100%;
	align-items: center;

	&:hover {
		border-bottom-color: #39c0ba !important;
		scale: 1.02;
	}

	@media (max-width: 768px) {
		// padding: 30px 28px 24px 28px;
		border-bottom: 7px solid;
		width: 100%;
	}

	.blogcard-title {
		margin-bottom: 32px;
		font-size: 22px;
		font-weight: 400;
		text-align: justify;
		margin-right: auto;
		font-family: "Roboto Slab", sans-serif !important;
		color: #000000;
		padding: 10px;

		@media (max-width: 768px) {
			padding-left: 12px;
			margin-bottom: auto;
		}

		@media (max-width: 1300px) {
			margin-bottom: 20px;
			font-size: 20px;
		}
	}

	.blogcard-text {
		font-size: 16px;
		font-family: "Hind", sans-serif;
		margin-left: 3%;
		color: #AAAAAA;
		padding: 10px;

		@media (max-width: 768px) {
			margin-top: 5%;
			padding: 0px;
			padding-left: 12px;
		}
	}

	img {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		height: 50%;
		width: auto;

		@media (max-width: 768px) {
			height: auto;
			width: auto;
		}
		
	}
}